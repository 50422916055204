import React, { Component } from 'react'
import ReactGA from 'react-ga'
import $ from 'jquery'
import './App.css'
import Hero from './Components/Hero'
import Header from './Components/Header'
import Footer from './Components/Footer'
import About from './Components/About'
import Resume from './Components/Resume'
import Experiance from './Components/Experience'
import Contact from './Components/Contact'
import Testimonials from './Components/Testimonials'
import Portfolio from './Components/Portfolio'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      foo: 'bar',
      resumeData: {},
    }
  }

  getResumeData() {
    $.ajax({
      url: '/resumeData.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data })
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err)
        alert(err)
      },
    })
  }

  componentDidMount() {
    this.getResumeData()
  }

  render() {
    return (
      <div className='App'>
	  {/*<Hero data={this.state.resumeData.main} />*/}
        <Header data={this.state.resumeData.main} />
        <About data={this.state.resumeData.main} />
			{/*<Experiance data={this.state.resumeData.resume} />*/}
        <Resume data={this.state.resumeData.resume} />
        <Portfolio data={this.state.resumeData.portfolio}/>
        <Footer data={this.state.resumeData.main} />
      </div>
    )
  }
}

export default App
